<template>
  <div class="infoPage">
<!--    <div class="pageNav">-->
    <div class="mengban"></div>
    <div class="img">
      <img v-if="navid==0" :src.sync="bannerImg[0]" width="100%" height="100%" alt="">
      <img v-if="navid==1" :src.sync="bannerImg[1]" width="100%" height="100%" alt="">
      <img v-if="navid==2" :src.sync="bannerImg[2]" width="100%" height="100%" alt="">
      <!--      <div class="img-icon">-->
      <!--        <img src="@/assets/img/shubiao.png" width="100%" height="100%" alt="">-->
      <!--      </div>-->
    </div>

<!--      <div class="nav_left">-->
<!--        <span class="zh">{{navid==0?'度假区时间线':navid==1?'展示荣誉':navid==2?'联系我们':''}}</span>-->
<!--        <span class="en">{{navid==0?'Resort Time First':navid==1?'Show Honor':navid==2?'Contact Us':''}}</span>-->
<!--        <span class="zh">关于我们</span>-->
<!--        <span class="en">About Us</span>-->
<!--      </div>-->
<!--      <div class="nav_right">-->
<!--        <div class="n_r_title">您当前的位置:</div>-->
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--          &lt;!&ndash;          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>&ndash;&gt;-->
<!--          <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid==0">度假区时间线</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid==1">展示荣誉</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item v-if="navid==2">联系我们</el-breadcrumb-item>-->
<!--        </el-breadcrumb>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="right">-->
<!--      <div class="right_1">-->
<!--        <div :class="navid==index?'zhong':'wei'" @click="navid=index" class="right_2" v-for="(item,index) of navlist" :key="index">{{item}}</div>-->
<!--      </div>-->
<!--      <ttime/>-->
      <deeds v-show="navid==0?true:false" />
      <honor v-show="navid==1?true:false" />
      <contact v-show="navid==2?true:false" />
<!--    </div>-->
  </div>
</template>

<script>
// import {} from "@/api";
import Homesixbackups from '../homeSixBackups/homeSixBackups'
import contact from "@/views/We/contact";
import honor from "@/views/We/honor";
import deeds from "@/views/We/deeds";
import {bannerList} from "@/api";
export default {
  name: "index",
  components:{
    contact,
    honor,
    Homesixbackups,
    deeds
  },
  data(){
    return {
      datalist:[],
      navlist:['度假区时间线','展示荣誉','联系我们'],
      navid:0,
      bannerImg:['../../assets/img/jiaotongtu.png','../../assets/img/jiaotongtu.png','../../assets/img/jiaotongtu.png']
      // bannerImg:['','','']
    }
  },
  watch:{
    $route(to,form) {
      // this.number++
      console.log(to,form)
      if(to.query.type){
        if(form.query.type != to.query.type){
          this.navid = to.query.type
        }
      }else{
        this.navid = '0'
      }
      // this.$router.go(0)
    },
    // deep: true
  },
  created() {
    if (this.$route.query.type){
      this.navid = this.$route.query.type
    }

    sessionStorage.setItem('RightImg','/img/swiperPage/LLGLCBT.png')
  },
  mounted() {
    this.getBanner('11')
    this.getBanner('12')
  },
  methods:{
    async getBanner(num){
      await bannerList({
        bannerType: num      // 11 大事记    12 联系我们
      }).then(res=>{
        // console.log(res)
        if(res.code==200){
          var bgImgList = res.rows[0].image.split(',')
          if(num == '11'){
            this.bannerImg[0] = bgImgList[0]
          }
          if(num == '12'){
            this.bannerImg[2] = bgImgList[0]
          }
          this.$forceUpdate()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.mengban{
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,123,138,.5);
  z-index: 99;
}
.img{
  width: 100%;
  height: 400px;
  position: relative;
  img{
    object-fit: cover;
  }
  .img-icon{
    width: 108px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -54px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% 100% 0 0;
  }
}
.zhong{
  background: rgba(51, 125, 141, 0.15);
  color: rgba(51, 125, 141, 1);
}
.wei{
  background: rgba(170, 170, 170, 0.15);
  color: rgba(51, 51, 51, 1);
}
.infoPage{
  width: 100%;
  //margin: 0 auto;
  //height: calc(100vh - 130px);
  //background: skyblue;
  //padding: 0 0 0 55px;
  padding-bottom: 200px;
  .pageNav{
    height: 88px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 50px;
    padding: 0 110px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav_left{
      .zh{
        font-size: 24px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #313131;
        //line-height: 33px;
        margin-right: 12px;
      }
      .en{
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #313131;
        //line-height: 28px;
      }
    }
    .nav_right{
      display: flex;
      //justify-content: center;
      .n_r_title{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
        margin-right: 20px;
      }
      /deep/.el-breadcrumb{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
      }
    }
  }
  .right{
    width: 1430px;
    //height: 800px;
    //overflow: scroll;
    ////background: skyblue;
    //&::-webkit-scrollbar {
    //  width: 0px;
    //}

    .right_1{
      //width: 492px;
      height: 70px;
      display: flex;
      //justify-content: space-between;
      justify-content: center;
      margin: 30px auto;
      .right_2{
        width: 224px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        cursor: pointer;
        margin-left: 40px;
        &:nth-child(1){
          margin-left: 0;
        }
      }
    }
  }
  /deep/.paging-device{
    margin: 20px 0;
    display: flex;
    justify-content: center;
    .el-pager li{
      height: 35px;
      line-height: 35px;
    }
    .btn-prev{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-right: 40px;
      i{
        font-size: 16px;
      }
    }
    .btn-next{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-left: 40px;
      i{
        font-size: 16px;
      }
    }
  }
}
</style>

